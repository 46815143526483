.homeSlider {
  max-width: 950px;
}
.homeSlider .sliderInrImg img {
  width: 100%;
  height: 120px;
  object-fit: contain;
  padding: 0 25px;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.center h3 {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.slick-next:before,
.slick-prev:before {
  color: #000;
}
.slider__carName {
  color: #565656;
  font-size: 1rem;
  font-weight: 700;
  border-left: 6px solid #b8b8b8;
  padding-left: 0.5rem;
  line-height: 1.438rem;
  margin-left: 1px;
}
.comparedWith p {
  font-size: 1.75rem;
  color: #565656;
}
.slick-slider .compareBtn {
  margin: 2.25rem 0 6.25rem;
}
.slick-slider .sliderBorders {
  border: #b8b8b8 solid 1px;
  border-radius: 5px;
}
.slider__segmentName {
  font-size: 1.2em;
}
.big-car .highlight-image img {
  max-width: 425px;
}
