body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul li {
  list-style-type: none;
}

a {
  display: inline;
  text-decoration: none;
}
.pageTitle h1 {
  color: #a3a3a3;
  font-size: 52px;
  font-weight: 300;
  margin: 50px 0;
}
.cbddRed {
  color: #d70808;
}

/****** Cross Site ******/
.compareBtn {
  font-size: 1.125em;
  color: #ffffff;
  font-weight: 700;
  background-color: #d70808;
  padding: 1.5rem 4.25rem;
  margin: 3.75rem 0;
}
.compareBtn:hover {
  color: #ffffff;
}
.align-center {
  margin: 0 auto;
}
.w180 {
  width: 180px;
}
.w220 {
  width: 220px;
}
.w235 {
  width: 235px;
}
/* Header */
.hpTitle {
  display: none !important;
}

header {
  background-color: rgba(255, 255, 255, 0.86);
}
.navbar-brand {
  padding: 20px 0 20px 45px;
}

.dropdown-header {
  color: #000;
  background-color: rgba(255, 255, 255, 0.86);
  border-color: #000;
}

.dropdown-header:hover {
  color: #000;
  background-color: rgba(255, 255, 255, 0.86);
  border-color: #000;
}
.dropdown-item:hover {
  background-color: transparent;
}
.dropdown-item .menuPicto {
  width: 143px;
  padding-top: 54px;
}
.dropdown-item .menuPicto:hover {
  color: #d70808;
}
.dropdown-item .menuPicto.seg2 {
  background: url('assets/images/picto/2.png') no-repeat;
}
.dropdown-item .menuPicto.seg2-active,
.dropdown-item .menuPicto.seg2:hover {
  background: url('assets/images/picto/2r.png') no-repeat;
}
.dropdown-item .menuPicto.seg3 {
  background: url('assets/images/picto/3.png') no-repeat;
}
.dropdown-item .menuPicto.seg3-active,
.dropdown-item .menuPicto.seg3:hover {
  background: url('assets/images/picto/3r.png') no-repeat;
}
.dropdown-item .menuPicto.seg4 {
  background: url('assets/images/picto/4.png') no-repeat;
}
.dropdown-item .menuPicto.seg4-active,
.dropdown-item .menuPicto.seg4:hover {
  background: url('assets/images/picto/4r.png') no-repeat;
}
.dropdown-item .menuPicto.seg5 {
  background: url('assets/images/picto/5.png') no-repeat;
}
.dropdown-item .menuPicto.seg5-active,
.dropdown-item .menuPicto.seg5:hover {
  background: url('assets/images/picto/5r.png') no-repeat;
}
.dropdown-item .menuPicto.seg6 {
  background: url('assets/images/picto/6.png') no-repeat;
}
.dropdown-item .menuPicto.seg6-active,
.dropdown-item .menuPicto.seg6:hover {
  background: url('assets/images/picto/6r.png') no-repeat;
}
.dropdown-item .menuPicto.seg7 {
  background: url('assets/images/picto/7.png') no-repeat;
}
.dropdown-item .menuPicto.seg7-active,
.dropdown-item .menuPicto.seg7:hover {
  background: url('assets/images/picto/7r.png') no-repeat;
}
.dropdown-item .menuPicto.seg8 {
  background: url('assets/images/picto/8.png') no-repeat;
}
.dropdown-item .menuPicto.seg8-active,
.dropdown-item .menuPicto.seg8:hover {
  background: url('assets/images/picto/8r.png') no-repeat;
}
.dropdown-item .menuPicto.seg9 {
  background: url('assets/images/picto/9.png') no-repeat;
}
.dropdown-item .menuPicto.seg9-active,
.dropdown-item .menuPicto.seg9:hover {
  background: url('assets/images/picto/9r.png') no-repeat;
}
.dropdown-item .menuPicto.seg10 {
  background: url('assets/images/picto/10.png') no-repeat;
}
.dropdown-item .menuPicto.seg10-active,
.dropdown-item .menuPicto.seg10:hover {
  background: url('assets/images/picto/10r.png') no-repeat;
}
.dropdown-item .menuPicto.seg11 {
  background: url('assets/images/picto/11.png') no-repeat;
}
.dropdown-item .menuPicto.seg11-active,
.dropdown-item .menuPicto.seg11:hover {
  background: url('assets/images/picto/11r.png') no-repeat;
}
.dropdown-item .menuPicto.seg12 {
  background: url('assets/images/picto/12.png') no-repeat;
}
.dropdown-item .menuPicto.seg12-active,
.dropdown-item .menuPicto.seg12:hover {
  background: url('assets/images/picto/12r.png') no-repeat;
}
.dropdown-item .menuPicto.seg13 {
  background: url('assets/images/picto/13.png') no-repeat;
}
.dropdown-item .menuPicto.seg13-active,
.dropdown-item .menuPicto.seg13:hover {
  background: url('assets/images/picto/13r.png') no-repeat;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}

.dropdown-header:not(:disabled):not(.disabled).active:focus,
.dropdown-header:not(:disabled):not(.disabled):active:focus,
.show > .dropdown-header.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}

.dropdown-header:not(:disabled):not(.disabled).active,
.dropdown-header:not(:disabled):not(.disabled):active,
.show > .dropdown-header.dropdown-toggle {
  color: #000;
  background-color: rgba(255, 255, 255, 0.86);
  border-color: #000;
}
.countrySelect .dropdown-header {
  font-size: 1.2rem;
  color: #666666;
  background-color: transparent;
  border: none;
  line-height: 1.2rem;
  padding: 0 0 20px 0;
}
.countrySelect .dropdown-toggle::after {
  content: url(assets/images/contryDropArrow.png);
  border: none;
  bottom: 2px;
  position: relative;
  padding-left: 3px;
}
.countrySelect.show > .dropdown-header.dropdown-toggle {
  background-color: transparent;
}
.countrySelect .dropdown-menu {
  margin: 0;
  padding: 0;
  width: auto;
  min-width: auto;
  border: none;
  border-radius: 0;
  background-color: #eeeeee;
}
.countrySelect .dropdown-item {
  padding: 0.2rem 1rem;
}
.countrySelect .dropdown-item.active {
  color: #666666;
  background-color: #eeeeee;
}
/* Header__Navigation */
.navbar-nav .dropdown .nav-link {
  padding: 0 0 15px 0;
}
.navbar-nav .nav-link {
  border-bottom: 5px solid transparent;
  color: #666666 !important;
  padding-bottom: 15px;
  font-size: 1.2rem;
}
.navbar-nav .nav-devider {
  width: 1px;
  background-color: #666666;
  height: 1.2em;
  top: 5px;
  position: relative;
}
.navbar-nav .dropdown-toggle::after {
  display: none;
}
.navbar-nav .nav-link:hover {
  color: #d70808;
  border-bottom: solid 5px #d70808;
}
.navbar-nav .dropdown-menu {
  margin: 0;
  border: none;
  left: 50%;
  position: fixed !important;
  transform: translate(-50%, 0);
  top: auto;
  padding: 0;
  border-bottom: solid 5px #d70808;
  border-radius: 0;
}
.navbar-nav .dropdown:hover > .dropdown-menu {
  display: block;
}
.navbar-nav .list-group-item {
  border: none;
  background-color: #eeeeee;
}
.panel-title,
.panel-title:active {
  display: block;
  padding: 15px;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 3px;
  text-decoration: none;
}

a.panel-heading:before {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
  float: right;
  transition: all 0.5s;
}
a.panel-heading.active:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* HP content */
.home .head_bg {
  background: url('assets/images/hpHeadBg.jpg') no-repeat;
  background-size: 100%;
}
.home .hpTitle {
  min-height: 729px;
  display: flex !important;
}
.home .hpTitle h1 {
  color: #fff;
  font-size: 5em;
  font-weight: 300;
}
.home .hpTitle h1 span.strong {
  font-weight: 900;
}
.home h2 {
  color: #a3a3a3;
  font-size: 3.25em;
  font-weight: 300;
  margin: 6.25rem 0 0;
}
/* spinner */
.spinner-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  background-color: #ffffff;
}

.center-Y {
  top: 30%;
}

.panel-heading {
  cursor: pointer;
}

.cm-spinner {
  height: 350px;
  width: 350px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 4px solid #f15e41;
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  position: relative;
  left: 50%;
  margin-left: -10%;
}

.cm-spinner-slider {
  left: 45%;
  margin-left: -15%;
}

.cm-spinner::before,
.cm-spinner::after {
  content: '';
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  border-radius: 50%;
  border: 4px solid transparent;
}

.cm-spinner::before {
  border-top-color: #bad375;
  -webkit-animation: 3s spin linear infinite;
  animation: 3s spin linear infinite;
}

.cm-spinner::after {
  border-top-color: #26a9e0;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* HP content - Search componenet*/
.home-search {
  margin-top: -45px;
}
@media (max-width: 1900px) {
  .home-search {
    margin-top: -125px;
  }
}
@media (max-width: 1550px) {
  .home-search {
    margin-top: -220px;
  }
}
.home-search .tab-content {
  background-color: #eeeeee;
  padding: 2em 0;
}
.home-search .tab-content input {
  background-color: #e0e0e0;
  color: #666666;
  font-size: 1.063em;
  line-height: 1;
  padding: 1.25em 0 1.25em 1.25em;
  height: auto;
}
.home-search .nav-tabs {
  border: none;
}
.home-search .nav-tabs .nav-link {
  background-color: #d7d7d7;
  border: none;
  border-top: transparent 7px solid;
  border-bottom: transparent 7px solid;
  color: #999999;
  border-radius: 0;
  font-size: 1.188em;
}
.home-search .nav-tabs .nav-link.active,
.home-search .nav-tabs .nav-link:hover {
  background-color: #eeeeee;
  border-top: #d70808 7px solid;
  border-bottom: #eeeeee 7px solid;
  color: #666666;
}
/* HP content - Search componenet*/
.home li.segmentList {
  border: none;
  background-color: transparent;
}
.home li.segmentList,
.home li.segmentList a {
  padding: 0;
}
.home li.segmentList .menuPicto {
  width: 130px;
  background-size: 130px;
  font-size: 0.9em;
}
.home li.segmentList .menuPicto:hover {
  width: 130px;
  background-size: 130px;
  font-size: 0.9em;
  color: #d70808;
}
#home-search-tabpane-second ul:nth-of-type(1) li.segmentList {
  padding-bottom: 1em;
}

/* COMPARE CARS */
.compareCars {
  background-color: #f7f7f7;
}
.compareCars h1 {
  color: #a3a3a3;
  font-size: 3.25em;
  font-weight: 300;
  margin: 6.25rem 0 1.6rem;
}
.carPlace {
  line-height: 1;
}
.carPlace__number {
  font-size: 4.25rem;
}
span.carPlace__sufx {
  top: -35px;
  position: relative;
}
.card-title .card__carName {
  font-size: 1rem;
}
.card__prices {
  color: #666666;
  line-height: 1.375em;
}
.card__prices i {
  font-size: 0.7em;
  margin-right: 4px;
}
.card_transPrice {
  font-size: 1.375em;
}
.card_listPrice.cross {
  text-decoration: line-through;
}
.card__addAnother {
  color: #666666;
}
.slick-slider .card {
  border: none;
  margin: 0;
}
.home .slick-slider .slick-prev {
  left: -150px;
}
.home .hpContent {
  width: 570px;
  margin: 0 auto;
  color: #565656;
}
.home .howWorks img {
  width: 100%;
}
.home .hpContent h3 {
  font-size: 28px;
  margin: 0 0 30px;
  font-weight: normal;
}
.home .hpContent p {
  font-size: 16px;
  text-align: justify;
}
.slick-slider .slick-prev {
  left: 0px;
  z-index: 10;
}
.slick-slider .slick-prev:before {
  content: url(assets/images/slickSliderLeft.png);
}
.home .slick-slider .slick-next {
  right: -150px;
}
.slick-slider .slick-next {
  right: 0px;
  z-index: 10;
}
.slick-slider .slick-next:before {
  content: url(assets/images/slickSliderRight.png);
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  width: 30px;
  height: 30px;
}
.panel-title.keyFeature,
.panel-title.keyFeature:active {
  color: #d70808;
  font-weight: normal;
  padding: 0;
  margin: 27px 0 15px;
}
.panel-title.keyFeature .panel-heading::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  margin-left: 6px;
}
i.keyFetIcon {
  font-size: 24px;
}
p.keyFetDesc {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px 16px;
}
/* Compare Car Segment */
.segmentLegend .list-group-item {
  border: none;
  padding: 0;
  background-color: transparent;
}
.segmentLegend ul:nth-of-type(1) li.segmentList {
  padding-bottom: 1em;
}
.segmentLegend li.segmentList .dropdown-item {
  padding-left: 34px;
  padding-right: 34px;
}
/* COMPARE RESULTS */
.compareResults {
  background-color: #f7f7f7;
}
.compareResults h1 {
  color: #a3a3a3;
  font-size: 3.25em;
  font-weight: 300;
  margin: 6.25rem 0 1.6rem;
}
.compareResults .nav-tabs {
  border: none;
}
.compareResults .nav-link {
  width: 60px;
  height: 60px;
  border-radius: 0;
}
.card__placeNumber {
  font-size: 4.25rem;
  line-height: 4.25rem;
}
.fa-check-circle,
.fa-times-circle {
  font-size: 40px;
}
.fa-check-circle {
  color: #2ac805;
}
.fa-times-circle {
  color: #d70808;
}
/* FOOTER */
footer {
  background-color: #eeeeee;
}
footer .left {
  background-color: #d70808;
  color: #ffffff;
}
footer .leftContent {
  padding-left: 12.5em;
}
footer .leftContent p {
  border-left: solid 6px #ffffff;
  padding-left: 1em;
}
footer nav,
footer nav a {
  color: #666666;
  font-weight: 100;
}
footer nav li:first-child {
  color: #d70808;
  font-size: 1.125em;
  font-weight: 700;
}

/* cookie pop-up */
div.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(0, 74, 151, 0.9);
}
div.cookies .container {
  position: relative;
}
@media only screen and (min-width: 1025px) {
  div.cookies .container {
    height: 40px;
  }
}
div.cookies .container div {
  padding-left: 60px;
  padding-right: 10px;
}
div.cookies p {
  color: #fff;
  text-decoration: underline;
  border: none;
}
div.cookies p.close-cookie-warning {
  position: absolute;
  left: 15px;
  top: 0;
  display: block;
  background-color: rgb(0, 74, 151);
  color: #fff;
  padding: 9px 5px;
  cursor: pointer;
  font-size: 4rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none;
  text-decoration: none;
}
div.cookies p.close-cookie-warning:hover {
  background-color: #fff;
  color: rgb(47, 153, 205);
  border-bottom: none;
}

#stars .gradient-scale {
  background-image: linear-gradient(
    #00ff00,
    #ffffff,
    #ffffff,
    #ffffff,
    #ff0000
  );
}

#stars .star-scale div {
  position: relative;
  margin-top: 10.4px;
  font-size: 0.9rem;
}

#stars .star-scale div:first-child {
  margin-top: 0px;
}

/* #stars .star-scale div:nth-child(2) {
  margin-top: 2px;
} */

#stars .star-scale div span:first-child {
  margin-left: 3px;
}

#stars .star-scale div span {
  display: inline-block;
  margin-right: 3px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

/*******************************/
/****** COMPARE CARS PAGE ******/
/*******************************/
.compareCarsInner {
  width: 1318px;
  margin: 0 auto;
}
.compareCarsInner .chosenCars {
  padding-top: 50px;
  padding-bottom: 50px;
}
.compareCars .card {
  background-color: transparent;
  padding: 0 9px;
  border: 0;
}
.compareCars .card-body {
  padding: 30px 20px 25px;
  color: #666666;
  font-size: 16px;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.compareCars .card-body.selected {
  padding: 30px 30px 25px;
}
.card-body p {
  margin-bottom: 0;
}
.card-body p.resaultListPrice {
  margin-bottom: 10px;
}

.card-body span.chooseBtn a {
  background-color: #d70808;
  border: none;
  border-radius: 6px;
  width: 197px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  margin: 20px auto 0;
  display: block;
  font-weight: bold;
  letter-spacing: 0.96px;
}
.winner .card-body span.chooseBtn a {
  background-color: #22b200;
}
.compareCars .card .searchCarsFeild {
  background-color: #eeeeee;
  color: #666666;
  width: 263px;
  height: 48px;
  margin: 0 auto;
  border: 0;
  position: relative;
  border-radius: 6px;
  margin: 10px auto 40px;
}
.compareCars .card .searchCarsFeild::before {
  content: '\f002';
  background-color: #d70808;
  width: 46px;
  height: 48px;
  top: 0;
  right: 0;
  position: absolute;
  border-radius: 0 6px 6px 0;
  font-family: 'Font Awesome 5 Pro';
  color: #fff;
  font-size: 20px;
  padding: 0 13px;
  line-height: 48px;
}
.compareCars .card input {
  border: none;
  background-color: transparent;
  height: 48px;
  width: 100%;
  padding: 0;
}
.compareCars .slick-slider .card-img-top {
  width: 180px;
  margin: 0 auto 20px;
}
.compareCars .card-title {
  color: #565656;
  font-size: 20px;
  font-weight: 700;
  border-left: 6px solid #b8b8b8;
  padding-left: 8px;
  line-height: 24px;
  margin: 0 0 24px;
}
.compareCars .slick-slider .card-title {
  font-size: 16px;
  padding-left: 8px;
  line-height: 21px;
  margin: 0 36px 30px;
}
.cardVersion,
.cardBrandModel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.cardPrices {
  margin: 30px 0;
}
.cardListPrice .price {
  text-decoration: line-through;
}
.cardTransPrice .price {
  font-size: 22px;
}
.compareBtnWrap {
  padding-bottom: 120px;
  margin-top: 50px;
}
.compareCars .compareBtn {
  height: 63px;
  width: 287px;
  padding: 0;
  margin: 0 auto;
}
.compareCars .compareBtn a {
  line-height: 63px;
  color: #ffffff;
}

.compareResults .nav-tabs {
  border: none;
}

/**************************/
/****** RESAULT PAGE ******/
/**************************/
.compareResultsInner {
  width: 1318px;
  margin: 0 auto;
}
.compareResults .nav-tabs.top {
  border: none;
  width: 200px;
  margin: 0 auto;
}
.compareResults .nav-tabs.top a {
  font-size: 30px;
  line-height: 60px;
  padding: 0;
  color: #000;
  background-color: #e5e5e5;
}
.compareResults .nav-tabs.top a.active {
  color: #e5e5e5;
  background-color: #000;
}
.resaultContainer {
  margin: 50px 0;
}
.resaultContainer .card-title {
  color: #565656;
  font-size: 20px;
  font-weight: 700;
  border-left: 6px solid #b8b8b8;
  padding-left: 8px;
  line-height: 24px;
  margin: 0 0 24px;
}
.cardTpWrap .card-title {
  border-left: 3px solid #b8b8b8;
  padding-left: 4px;
  line-height: 12px;
  margin: 0 0 12px;
}
.resaultContainer .card-title .rating {
  font-size: 18px;
}
#compare-results-tabpane-second #stars {
  margin-right: -40px;
}
#compare-results-tabpane-third .resaultContainer .card-title .rating span {
  margin-right: 2px;
}
.resaultContainer .card-title .rating span {
  margin-right: 6px;
  color: #d70808;
}
.resaultContainer .card {
  background-color: transparent;
  border: 0;
}
#compare-results-tabpane-first .resaultContainer .card {
  padding: 0 9px;
}
.resaultContainer .card-body {
  padding: 30px 20px 25px;
  color: #666666;
  font-size: 16px;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.resaultContainer .card-body img.card-img-top {
  height: 130px;
  object-fit: contain;
  margin-bottom: 15px;
}
#compare-results-tabpane-third .resaultContainer .card-body {
  padding: 0 0 25px;
  background-color: transparent;
  border: none;
  width: 220px;
}
#compare-results-tabpane-third .resaultContainer table {
  font-size: 20px;
  margin-bottom: 0;
}
#compare-results-tabpane-third .resaultContainer table td:first-child {
  font-size: 16px;
}
.advDis tr:first-child td,
.allpar tr:first-child td {
  border-top: none;
}
.allParamsBtnWrap .flex-fill {
  height: 1px;
  background-color: #b8b8b9;
}
button.allParamsBtn {
  background-color: #eee;
  width: 260px;
  border-radius: 16px;
  border: solid 1px #b8b8b8;
  font-size: 16px;
  color: #666;
  line-height: 16px;
  padding: 12px 0;
  position: relative;
  margin: 16px 0;
}
button.allParamsBtn.clps::before {
  content: '\f055';
  font-family: 'Font Awesome 5 Pro';
  font-size: 24px;
  font-weight: lighter;
  position: relative;
  vertical-align: middle;
  margin-right: 24px;
  transition: 0.5s all;
}
button.allParamsBtn.minus-clps::before {
  content: '\f056';
  font-family: 'Font Awesome 5 Pro';
  font-size: 24px;
  font-weight: lighter;
  position: relative;
  vertical-align: middle;
  margin-right: 24px;
  transition: 0.5s all;
}
.winner::before {
  background: url(assets/images/bestValueBnr.png) no-repeat #fff;
  position: absolute;
  width: 153px;
  height: 44px;
  content: ' ';
  right: 19px;
  top: -16px;
  z-index: 10;
}
#compare-results-tabpane-third .winner::before {
  width: 114px;
  height: 35px;
  right: 20px;
  top: 0;
  background-size: 120px 35px;
}
#chartToolTip.winner::before {
  width: 77px;
  height: 22px;
  right: 8px;
  top: -9px;
  background-size: 77px 22px;
}
.winner .carPlace {
  color: #d70808;
}
.compareResults .nav-tabs.resultPagin {
  border: none;
  margin: 0 auto;
}
.compareResults .nav-tabs.resultPagin .nav-item {
  border: none;
  margin: 0 30px;
}
.compareResults .nav-tabs.resultPagin a {
  padding: 0;
  height: 63px;
  width: 287px;
  line-height: 63px;
  margin: 0;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}
/*************************/
/***** Find A Dealer *****/
/*************************/
.findDealerWrapper {
  background-color: #f7f7f7;
}
.findDealerWrapper h1 {
  color: #a3a3a3;
  font-size: 52px;
  font-weight: 300;
  margin: 50px 0;
}
.findDealerContent {
  width: 1328px;
  margin: 0 auto;
}
.findDealerContent #filter {
  width: 367px;
  background-color: #eee;
  padding: 30px 25px 80px;
  font-size: 14px;
}
.findDealerContent #filter .form-group {
  margin-bottom: 30px;
  border-bottom: 1px solid #a3a3a3;
}
.findDealerContent #filter .filterTitle {
  font-size: 20px;
  line-height: 1;
  padding-bottom: 15px;
  font-weight: bold;
}
.findDealerContent #filter .custom-control {
  padding-left: 0;
  margin-bottom: 8px !important;
}
.findDealerContent #filter .custom-control-label::before {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: unset;
  height: unset;
  pointer-events: none;
  content: '\f0c8';
  background-color: #eee;
  border: none;
  font-size: 20px;
  font-family: 'Font Awesome 5 Pro';
  margin-right: 10px;
  vertical-align: middle;
}
.findDealerContent #filter .custom-control-label::after {
  content: unset;
}
.findDealerContent
  #filter
  .custom-control-input:checked
  ~ .custom-control-label::before {
  content: '\f14a';
  font-weight: 900;
  color: #666;
}
.findDealerContent #dealers {
  width: 903px;
}
.findDealerContent #dealers .card {
  border: none;
  border-radius: 0;
  margin-bottom: 15px;
}
.findDealerContent #dealers button,
#reset-filter {
  width: 247px;
  height: 70px;
  border: solid 3px #d70808;
  background-color: #fff;
  color: #d70808;
  font-size: 18px;
  border-radius: 0;
}
.findDealerContent #dealers button:hover {
  border: solid 3px #000;
  background-color: #000;
  color: #fff;
}
.findDealerContent #dealers .card-img {
  width: 229px;
  border-radius: calc(0.25rem - 1px);
  padding: 40px 16px;
  border: solid 1px #b8b8b8;
  margin-right: 20px;
}
.findDealerContent #dealers .card-text {
  font-size: 18px;
  font-weight: bold;
  color: #565656;
}

/***********/
/*** TOS ***/
/***********/

#tos {
  width: 860px;
}
#tos ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0 0 20px;
  padding: 0;
}

#tos li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  font-weight: bold;
  text-align: justify;
}

#tos li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

#tos li li {
  margin: 0 0 5px;
  font-weight: normal;
}
#tos li li:first-child {
  margin-top: 10px;
}

li li:before {
  content: counters(item, '.') ' ';
}

/* pricing plans */
h2.register {
  margin: 0px 0px 20px 0px;
}
.modal-90w {
  max-width: 90% !important;
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

.pricing_plan {
  display: none;
}

.pricing_plan:checked + .card {
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    cursor: pointer;
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}

/* new custom styles */
.min-wp-100 {
  min-width: 100%;
}

#react-autowhatever-home-search li:hover {
  background-color: #eeeeee;
}

#react-autowhatever-home-search li a {
  display: block;
}

.no-results-home-search {
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu.min-wp-100.show {
  max-height: 300px;
  overflow-y: auto;
}

/* Style the header */
#stickyInfo {
  padding: 10px 16px;
  background: #e0e0e0;
  color: #f1f1f1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.37);
}
#stickyInfo button {
  background-color: transparent;
  border: none;
  line-height: 1;
  padding: 0 0 0 20px;
}
.head_bg {
  margin-top: 34px;
}

.buttonInModal {
  display: inline-block;
  color: #ffffff;
  background-color: #d70808;
  padding: 10px 10px;
  border-radius: 10px;
}

.buttonInModal a {
  text-decoration: none;
  color: #fff;
}

.fixed-height {
  max-height: 360px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.padding20 {
  padding-bottom: 20px;
}

.height985 {
  min-height: 985px;
}

.badge-pointer {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.personal-page .head_bg,
.about .head_bg,
.howitworks .head_bg {
  background: none;
}
.readMoreBtn {
  width: 270px;
  height: 58px;
  border: solid 2.5px #000000;
  padding: 0 28px 0;
  margin-top: 30px;
}
.readMoreBtn a {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: 1.02px;
  text-align: left;
  color: #000000;
}
.readMoreBtn i {
  float: right;
  line-height: 53px;
  font-size: 17px;
}
.registarModal .modal-dialog {
  max-width: 400px;
}
.modal-header {
  position: absolute;
  border: none;
  right: 15px;
  z-index: 9999;
}
.modal-body {
  padding-bottom: 0;
}
.modal-body .registerTitle {
  font-size: 40px;
  color: #a3a3a3;
  font-weight: 200;
}
.registarModal label {
  margin-bottom: 2px;
  font-size: 14px;
}
.registarModal .registerBtn button {
  background-color: grey;
  font-size: 20px;
  color: #fff;
  padding: 10px 24px;
  line-height: 24px;
  width: 100%;
  font-weight: Normal;
}
.registarModal .modal-footer {
  border: none;
  padding: 0;
}
.registarModal p.tos {
  font-size: 12px;
  width: 100%;
}
.container.aboutCBDD {
  padding-bottom: 50px;
}
.container.aboutCBDD h1 {
  color: #a3a3a3;
  font-size: 3.25em;
  font-weight: 300;
  margin: 0 0 20px;
  padding-top: 30px;
}
.howitworks li {
  list-style-type: circle;
}
.question {
  font-weight: bold;
}
.privacyPolicy ul li {
  list-style-type: unset;
}

.hide {
  display: none;
}

input[type='range'] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #d70808;
  border-radius: 25px;
}
input[type='range']::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #d70808;
}
.range-wrap {
  position: relative;
}
.range-value {
  position: absolute;
  top: -40%;
}
.range-value span {
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #d70808;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}
.range-value span:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid #d70808;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.finddealer .modal-header,
#pricing-plan-header {
  position: relative;
  right: 0px;
}

#pricing-plan-footer {
  border-top: none;
}
